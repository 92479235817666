// NOTE: effect_price just 特例，迁移旧官网遗留路由
import { SEO } from '@renderbus/common/components'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../molecules/layout'
import {
  ContentContainer,
  ModalContent,
  ModalText,
  ModalButton,
} from './architectural-rendering-monthly-promotion.atom'

import { UserAuthorizationService, baseLink, isTest } from '@renderbus/common/service'
import ModalBg from '../images/architectural-rendering-monthly-promotion/modal-bg.png'
import ModalBgSmall from '../images/architectural-rendering-monthly-promotion/modal-bg-small.png'
import CloseIcon from '../images/architectural-rendering-monthly-promotion/close.png'

const TypeEnum = {
  NORMAL: 0, // 正常获取到
  DISTRIBUTED: 1, // 已发放完未获取到
  FAILED: 2, // 获取失败
  NOT_PAID: 3, // 未充值
}
class ArchitecturalRenderingMonthlyPromotion extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      type: TypeEnum.NOT_PAID,
      redemptionCode: null,
      iFrameHeight: 2500,
    }
  }
  handleClick(e) {
    if (typeof window !== 'undefined') {
      console.log(!!UserAuthorizationService.isLogined())
      window.open(
        !!UserAuthorizationService.isLogined()
          ? `${baseLink(true)}/center/user/topUp`
          : `${baseLink()}/sso?redirectPath=/center/user/topUp`,
        '_blank',
      )
      this.setState({
        showModal: false,
      })
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.handleMessage)
    // window.addEventListener('scroll', this.getHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage)
    // window.removeEventListener('scroll', this.getHeight)
  }

  // getHeight = event => {
  //   const i = document.getElementById('double-eleven')
  //   if (i) {
  //     i.contentWindow.postMessage('requestHeight', '*')
  //   }
  // }

  handleMessage = event => {
    if (event.data.height) {
      this.setState({
        iFrameHeight: event.data.height,
      })
    }
    if (event.data.openHref) {
      window.open(event.data.openHref, '_blank')
    }
    if (event.data.showModal) {
      this.setState({
        showModal: true,
        type: event.data.type,
        redemptionCode: event.data.redemptionCode,
      })
    }
    if (event.data.gotoRecharge) {
      this.handleClick()
    }
  }
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    const { showModal, type, redemptionCode, iFrameHeight } = this.state
    return (
      <StaticQuery
        query={graphql`
          query {
            bg: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/bg.png/" }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({}) => (
          <Layout location={location} topThreeShare={topThreeShare}>
            <SEO
              title='2024双十一狂欢节,效果图渲染充值大促-瑞云渲染'
              keywords='效果图渲染双十一活动,效果图渲染双十一优惠,效果图渲染充值优惠'
              description='瑞云渲染2024双十一狂欢节盛大开启！特别推出效果图渲染充值大促销活动，限充值可享受额外赠送的渲染券，充值金额越多，赠送的渲染券比例越高，最高可达100%。确保每位效果图用户在双十一都能享受到超值的优惠，体验到瑞云渲染的高效和专业服务，让您的设计项目以更低成本获得更高质量的视觉效果。'
              sharePostSlug='architectural-rendering-monthly-promotion.html'
            />
            <ContentContainer theme='black'>
              <iframe
                id='double-eleven'
                title='双十一活动'
                scrolling='no'
                src={
                  isTest()
                    ? 'https://kuaitu.renderbus.com:8080/architectural-rendering-monthly-promotion/double-eleven'
                    : 'https://kuaitu.renderbus.com//architectural-rendering-monthly-promotion/double-eleven'
                }
                // src={
                //   'http://localhost:3001/architectural-rendering-monthly-promotion/double-eleven'
                // }
                frameborder='0'
                width='100%'
                height={`${iFrameHeight}px`}
                loading='eager'
                onLoad={() => {
                  console.log('加载完毕')
                  const i = document.getElementById('double-eleven')
                  if (i) {
                    setTimeout(() => {
                      console.log('延迟一下')
                      i.contentWindow.postMessage('requestHeight', '*')
                    }, 2000)
                  }
                }}
              ></iframe>
              {showModal && type === TypeEnum.NORMAL && (
                <ModalContent>
                  <div className='mask'>
                    <div className='dialog-content'>
                      <img src={ModalBg} alt='' className='bg' />
                      <img
                        src={CloseIcon}
                        alt=''
                        className='close'
                        onClick={() => this.setState({ showModal: false })}
                      />
                      <ModalText>
                        <p className='title'>欧模网大会员兑换</p>
                        <div className='detail'>
                          <p>兑换成功！</p>
                          <p>
                            您的欧模网大会员月卡兑换码为：
                            <span className='code'>{redemptionCode}</span>
                          </p>
                        </div>
                        <div className='detail'>
                          <p>复制兑换码，前往欧模网会员页</p>
                          <p>
                            <a
                              href='https://www.om.cn/vip/integal'
                              className='oumo-link'
                              target='_blank'
                            >
                              https://www.om.cn/vip/integal
                            </a>{' '}
                            兑换使用
                          </p>
                        </div>
                      </ModalText>
                    </div>
                  </div>
                </ModalContent>
              )}
              {showModal && type === TypeEnum.DISTRIBUTED && (
                <ModalContent>
                  <div className='mask'>
                    <div className='dialog-content'>
                      <img src={ModalBgSmall} alt='' className='bg' />
                      <img
                        src={CloseIcon}
                        alt=''
                        className='close'
                        onClick={() => this.setState({ showModal: false })}
                      />
                      <ModalText>
                        <p className='title'>欧模网大会员兑换</p>
                        <div className='detail'>
                          <p>活动期间会员码已经兑换完，有疑问请联系</p>
                          <p>客服处理。</p>
                        </div>
                      </ModalText>
                    </div>
                  </div>
                </ModalContent>
              )}
              {showModal && type === TypeEnum.FAILED && (
                <ModalContent>
                  <div className='mask'>
                    <div className='dialog-content'>
                      <img src={ModalBgSmall} alt='' className='bg' />
                      <img
                        src={CloseIcon}
                        alt=''
                        className='close'
                        onClick={() => this.setState({ showModal: false })}
                      />
                      <ModalText>
                        <p className='title'>欧模网大会员兑换</p>
                        <div className='detail'>
                          <p>抱歉！兑换失败了，联系客服补发吧~</p>
                        </div>
                      </ModalText>
                    </div>
                  </div>
                </ModalContent>
              )}
              {showModal && type === TypeEnum.NOT_PAID && (
                <ModalContent>
                  <div className='mask'>
                    <div className='dialog-content'>
                      <img src={ModalBg} alt='' className='bg' />
                      <img
                        src={CloseIcon}
                        alt=''
                        className='close'
                        onClick={() => this.setState({ showModal: false })}
                      />
                      <ModalText>
                        <p className='title'>欧模网大会员兑换</p>
                        <p className='detail'>
                          完成充值满100元可获得兑换码，请先在【瑞云快图】完成登录/充值后，再返回页面查看兑换码~
                        </p>
                        <ModalButton onClick={this.handleClick}>立即登录/充值&gt;</ModalButton>
                      </ModalText>
                    </div>
                  </div>
                </ModalContent>
              )}
            </ContentContainer>
          </Layout>
        )}
      />
    )
  }
}

export default ArchitecturalRenderingMonthlyPromotion
