import { Media, hideMDDown, hideSMUp, mbSize, pcSize } from '@renderbus/common/theme'
import { Button } from '@renderbus/common/components'

import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import BtnBg from '../images/architectural-rendering-monthly-promotion/recharge-btn.png'
import BtnBg2 from '../images/architectural-rendering-monthly-promotion/recharge-btn2.png'

export const GatsbyImg = styled(Img)`
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`

export const BgImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100vw;
  bottom: 0;
  right: 0;
`

export const InnerImg = styled(Img)`
  position: absolute !important;
  top: ${pcSize(-104)};
  left: 50%;
  width: ${pcSize(1730)};
  height: ${pcSize(1687)};
  transform: translate(-50%, 0);
  ${hideMDDown};
`

export const RabbitImg = styled(Img)`
  position: absolute !important;
  top: ${pcSize(-180)};
  left: ${pcSize(272)};
  width: ${pcSize(427)};
  height: ${pcSize(367)};
  ${hideMDDown};
`

export const RabbitImgMb = styled(Img)`
  position: absolute !important;
  left: ${mbSize(310)};
  bottom: ${mbSize(48)};
  width: ${mbSize(301)};
  height: ${mbSize(241)};
  ${hideSMUp};
`

export const FlowerImg = styled(Img)`
  position: absolute !important;
  left: ${mbSize(410)};
  top: ${mbSize(-50)};
  width: ${mbSize(73)};
  height: ${mbSize(59)};
  ${hideSMUp};
`

export const ContentContainer = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  .hideSMUp {
    ${hideSMUp};
  }
  .hideMDDown {
    ${hideMDDown};
  }
  ${Media.lessThan(Media.small)} {
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100vw;
  height: ${pcSize(1648)};
  z-index: 1;
  padding-top: ${pcSize(202)};
  .mb {
    display: none !important;
  }
  ${Media.lessThan(Media.small)} {
    padding-top: ${mbSize(56)};
    height: max-content;
    padding-bottom: ${mbSize(57)};
    .mb {
      display: flex !important;
    }
    .pc {
      display: none !important;
    }
  }
`

export const RulesItem = styled.div`
  display: flex;
  text-align: start;
  font-size: ${pcSize(24)};
  color: #1f6dcc;
  line-height: ${pcSize(42)};
  width: ${pcSize(935)};
  font-size: ${pcSize(22)};
  color: #b2bcfd;
  img {
    width: ${pcSize(19)};
    height: ${pcSize(19)};
    margin: ${pcSize(12)} ${pcSize(15)} ${pcSize(12)} 0;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(22)};
    line-height: ${mbSize(42)};
    width: ${mbSize(549)};
    img {
      width: ${mbSize(19)};
      height: ${mbSize(19)};
      margin: ${mbSize(12)} ${mbSize(15)} ${mbSize(12)} 0;
    }
  }
`

export const RulesWrapper = styled.div`
  width: ${pcSize(1145)};
  height: ${pcSize(412)};
  background: #3b4898;
  border-radius: 20px;
  z-index: 1;
  padding: ${pcSize(39)} 0 0 ${pcSize(53)};
  .title {
    font-weight: 600;
    font-size: ${pcSize(24)};
    color: #b2bcfd;
    text-align: start;
    margin-bottom: ${pcSize(5)};
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(620)};
    height: max-content;
    min-height: ${mbSize(561)};
    padding: ${mbSize(50)} 0 ${mbSize(40)} ${mbSize(35)};
    border-radius: ${mbSize(20)};
    .title {
      font-size: ${mbSize(24)};
    }
  }
`

export const CardImg = styled(Img)`
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`

export const CardTitleImg = styled(Img)`
  width: ${pcSize(226)};
  height: ${pcSize(59)};
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(182)};
    height: ${mbSize(49)};
  }
`

export const CardTitle = styled.div`
  position: absolute;
  top: ${pcSize(-16)};
  left: 50%;
  transform: translate(-50%, 0);
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: ${pcSize(24)};
    color: #fff0bc;
    line-height: 1;
  }
  ${Media.lessThan(Media.small)} {
    top: ${mbSize(-12)};
    span {
      font-size: ${mbSize(19)};
    }
  }
`

export const CardItem = styled.div`
  display: flex;
  .price {
    position: relative;
    display: flex;
    align-items: flex-end;
    color: #67360d;
    font-weight: 800;
    line-height: 1;
  }
`

export const GetIcon = styled.div`
  position: relative;
  width: ${pcSize(63)};
  height: ${pcSize(63)};
  span {
    position: absolute;
    top: ${pcSize(13)};
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 28px;
    color: #ffedac;
    line-height: 1;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(54)};
    height: ${mbSize(54)};
    span {
      top: ${mbSize(12)};
      font-size: ${mbSize(22)};
    }
  }
`
export const RechargeIcon = styled.div`
  position: relative;
  width: ${pcSize(54)};
  height: ${pcSize(54)};
  span {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 22px;
    color: #b04d15;
    line-height: 1;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(46)};
    height: ${mbSize(46)};
    span {
      top: ${mbSize(10)};
      font-size: ${mbSize(18)};
    }
  }
`

export const RechargeItem = styled(CardItem)`
  .price {
    margin-left: ${pcSize(12)};
    top: ${pcSize(-5)};
  }
  .value {
    font-size: ${pcSize(50)};
  }
  .unit {
    font-size: ${pcSize(22)};
    margin: 0 0 ${pcSize(4)} ${pcSize(6)};
  }
  ${Media.lessThan(Media.small)} {
    .price {
      margin-left: ${mbSize(8)};
      top: -3px;
    }
    .value {
      font-size: ${mbSize(40)};
    }
    .unit {
      font-size: ${mbSize(18)};
      margin: 0 0 ${pcSize(4)} ${pcSize(4)};
    }
  }
`

export const GetItem = styled(CardItem)`
  margin-top: ${pcSize(16)};
  .price {
    margin-left: ${pcSize(3)};
    top: ${pcSize(-5)};
  }
  .value {
    font-size: ${pcSize(68)};
  }
  .unit {
    font-size: ${pcSize(28)};
    margin: 0 0 ${pcSize(6)} ${pcSize(6)};
  }
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(10)};
    .price {
      margin-left: 0;
      top: -2px;
    }
    .value {
      font-size: ${mbSize(54)};
    }
    .unit {
      font-size: ${mbSize(22)};
      margin: 0 0 4px 4px;
    }
  }
`

export const Gift = styled.div`
  text-align: center;
  position: relative;
  margin-top: ${pcSize(19)};
  width: 100%;
  font-size: ${pcSize(28)};
  color: #cf4309;
  span {
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(10)};
    font-size: ${mbSize(22)};
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${pcSize(347)};
  height: ${pcSize(311)};
  margin: 0 ${pcSize(60)} ${pcSize(75)} 0;
  padding: ${pcSize(85)} 0 0 0;
  ${CardItem} {
    margin-left: ${pcSize(32)};
  }
  ${p =>
    [0, 1].includes(p.index) &&
    css`
      padding: ${pcSize(89)} 0 0 0;
      ${CardItem} {
        margin-left: ${pcSize(55)};
      }
    `}
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(270)};
    height: ${mbSize(240)};
    margin: 0 ${mbSize(51)} ${mbSize(48)} 0;
    padding: ${mbSize(71)} 0 0 0;
    ${CardItem} {
      margin-left: ${mbSize(24)};
    }
    ${p =>
      [0, 1].includes(p.index) &&
      css`
        ${CardItem} {
          margin-left: ${mbSize(43)};
        }
      `}
  }
`

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: ${pcSize(1300)};
  position: relative;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(660)};
    left: 50%;
    transform: translate(-50%, 0);
    justify-content: flex-start;
  }
`

export const RechargeButton = styled.button`
  all: unset;
  cursor: pointer;
  position: relative;
  width: ${pcSize(259)};
  height: ${pcSize(65)};
  margin: ${pcSize(-16)} 0 ${pcSize(50)} 0;
  span {
    position: absolute;
    left: 50%;
    top: ${pcSize(16)};
    font-size: ${pcSize(24)};
    color: #fffae7;
    line-height: 1;
    transform: translate(-50%, 0);
  }
  .hover {
    visibility: hidden;
  }
  &:hover {
    .normal {
      visibility: hidden;
    }
    .hover {
      visibility: visible;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(259)};
    height: ${mbSize(65)};
    margin: ${mbSize(6)} 0 ${mbSize(55)} 0;
    span {
      top: ${mbSize(16)};
      font-size: ${mbSize(24)};
    }
  }
`

export const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  .mask {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .dialog-content {
    position: relative;
    z-index: 2;
    .bg {
      width: 690px;
    }

    .close {
      position: absolute;
      top: 118px;
      right: 0;
      cursor: pointer;
      z-index: 12;
    }
  }
  ${Media.lessThan(Media.small)} {
    .dialog-content {
      .bg {
        width: ${mbSize(621)};
      }

      .close {
        top: ${mbSize(45)};
      }
    }
  }
`
export const ModalText = styled.div`
  position: absolute;
  top: 0;
  padding: 319px 90px 0 80px;
  width: 100%;
  p {
    margin: 0;
  }
  .title {
    font-weight: 700;
    font-size: 40px;
    color: #ea8900;
    line-height: 60px;
  }
  .detail {
    font-weight: 400;
    font-size: 24px;
    color: #925b0c;
    line-height: 36px;
    margin-top: 20px;
    .code {
      background: #fffbcb;
      font-weight: 700;
    }
    .oumo-link {
      font-weight: 700;
      text-decoration: underline;
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(270)} ${mbSize(85)} 0 ${mbSize(80)};
    .title {
      font-size: ${mbSize(40)};
      line-height: ${mbSize(60)};
    }
    .detail {
      font-size: ${mbSize(24)};
      line-height: ${mbSize(36)};
      margin-top: ${mbSize(14)};
    }
  }
`
export const ModalButton = styled.div`
  background-image: ${`url(${BtnBg})`};
  background-size: 100% 100%;
  width: 314px;
  height: 102px;
  font-weight: 700;
  font-size: 28px;
  color: #ffffff;
  line-height: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 60px;
  cursor: pointer;
  :hover {
    background-image: ${`url(${BtnBg2})`};
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(314)};
    height: ${mbSize(102)};
    font-size: ${mbSize(24)};
    line-height: ${mbSize(102)};
    margin-top: ${mbSize(27)};
  }
`
